<template>
	<div>
		<div class="main-contents">
			<div class="mem_container">
				<div class="mem_info_contents">
					<div class="mem_contents_header">
						<div class="title-txt">비밀번호 찾기<br/><span>비밀번호를 분실 하셨나요?</span></div>
					</div>
					<div class="contents-body">
						<div class="contents-body-box re_pw">
							<div class="guide-text">
								<span class="pw">회원님의 비밀번호를 재설정 해 주세요!</span><br/>
							</div>
							<div class="mem-id-input">
								<div class="pwbox">
									<span>비 밀 번 호</span><input type="password" name id="txt_pw01" v-model="mberInfo.password" ref="password" @input="pwdSameChk()">
								</div>
								<div class="pwbox">
									<span>비밀번호 확인</span><input type="password" name id="txt_pw02" v-model="mberInfo.passwordCfm" ref="passwordCfm" @input="pwdSameChk()">
								</div>
							</div>
							<div class="confirm-btn pw" @click="pwdReset()">
								비밀번호 재설정
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- 팝업창 -->
		<div class="popup active" v-if="alert">
			<div class="notice_popup">
				<div class="close">
					<img src="/images/mem/close.png" alt="닫기" @click="btnClose()"/>
				</div>
				<div class="notice-text">비밀번호가 변경되었습니다!</div>
				<div class="close-btn" @click="btnClose()">닫기</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			mberInfo : {
				id : '',
				password : '',
				passwordCfm : ''
			},
			pwdSamePass : false,
			forwardData : this.$route.params.forwardData,
			alert : false
		}
	},

	beforeMount() {
		if(this.forwardData) {
			this.mberInfo.id = this.forwardData.mberId;
			this.title = this.forwardData.title;
		}
	},

	methods : {
		pwdReset() {
			if(!this.mberInfo.password) {
				alert('비밀번호를 입력하세요.');
				this.$refs.password.focus();
				return false;
			}

			if(!this.mberInfo.passwordCfm) {
				alert('비밀번호 확인을 입력하세요.');
				this.$refs.passwordCfm.focus();
				return false;
			}

			if(!this.pwdSamePass) {
				alert('비밀번호와 비밀번호확인이 일치하지 않습니다.');
				return false;
			}

			// 비밀번호 형식 체크 
			const passTest = /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[`~!@#$%^&*()_+=-]).{8,16}$/;
			var passRes = passTest.test(this.mberInfo.password);
			if(!passRes) {
				alert("비밀번호는 8~16자의 영문 / 숫자 / 특수문자를 모두 사용해야합니다!");
				return false;
			}

			this.$.httpPost('api/mem/passwordChange', this.mberInfo, '')
				.then(res => {
					if(res.data.resultCode == '000') {
						this.alert = true;
					}
				})
				.catch(err => {
					alert('ERROR :', err.message);
				});
		},

		// 비밀번호/비밀번호확인 일치 체크
		pwdSameChk() {
			if(this.mberInfo.password == this.mberInfo.passwordCfm) {
				this.pwdSamePass = true;
			}else {
				this.pwdSamePass = false;
			}
		},

		btnClose() {
			var param = {
				title : this.title
			}

			this.$router.push({name:'MEM002M04', params:{forwardData : param}});
		}
	}
}
</script>